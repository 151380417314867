import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Select,
  Checkbox,
  FormErrorMessage,
  InputGroup,
  InputLeftElement,
  FormHelperText,
  Textarea,
  Divider,
  Spacer,
  Text,
  Button,
  Collapse,
  Flex,
  useToast,
} from "@chakra-ui/react"
import { EmailIcon } from "@chakra-ui/icons"
import React, { useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { Formik, Form, Field, ErrorMessage } from "formik"
import * as Yup from "yup"

const ADD_SUBMISSION = gql`
  mutation AddSubmission(
    $email: String!
    $feature: String
    $feedbackMsg: String
    $subscribed: Boolean!
  ) {
    createFeedbackSubmission(
      data: {
        email: $email
        feature: $feature
        feedbackMsg: $feedbackMsg
        subscribed: $subscribed
      }
    ) {
      _id
    }
  }
`
const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
})

export default function ContactForm({ height }) {
  const [addSubmission, { data, loading, error }] = useMutation(ADD_SUBMISSION)
  const [subscribed, setSubscribed] = useState(true)
  const [show, setShow] = useState(false)
  const toast = useToast()
  const handleShowToggle = () => setShow(!show)

  return (
    <Flex
      justifyContent="flex-start"
      flexDirection="column"
      maxHeight={900}
      w={height * 0.731}
      maxWidth={700}
      borderRadius="lg"
      pl={12}
      pr={12}
      boxShadow="xl"
      bg="white"
      m="auto"
      mt={6}
    >
      <Heading textAlign="center" fontSize="x-large" mb={2}>
        Sign up to be the first to recieve updates!
      </Heading>
      <Formik
        initialValues={{
          email: "",
          likedFeature: "",
          feedback: "",
          subscribed: true,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, actions) => {
          addSubmission({
            variables: {
              email: values.email,
              feature: values.likedFeature,
              feedbackMsg: values.feedback,
              subscribed: values.subscribed,
            },
          })
            .then((e) => actions.setSubmitting(false))
            .then((e) =>
              toast({
                title: "Message Sent. Thank you for your feedback.",
                status: "success",
                isClosable: true,
                duration: 2000,
              })
            )
        }}
      >
        {(props) => (
          <Form>
            <Field name="email">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  isRequired
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<EmailIcon color="blue.300" />}
                    />
                    <Input
                      {...field}
                      id="email"
                      isRequired
                      variant="filled"
                      size="md"
                      type="email"
                      placeholder="Email"
                    />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Text>What feature are you most excited about?</Text>
            <Field name="likedFeature">
              {({ field, form }) => (
                <Select
                  {...field}
                  variant="filled"
                  mt={0}
                  placeholder="Select option"
                >
                  <option value="Track All Donations">
                    Being able to track all of my donations in one place.
                  </option>
                  <option value="Portfolio of Nonprofits">
                    Creating my own portfolio of nonprofits so I can give to
                    them whenever I want.
                  </option>
                  <option value="Impact of Donations">
                    Having the ability to view the impact of my donations.
                  </option>
                  <option value="More Effective Donation">
                    Being able to more effectively donate through data.
                  </option>
                  <option value="Other">Other. Please describe below.</option>
                </Select>
              )}
            </Field>
            <Heading fontSize="lg">We'd love to hear any feedback!</Heading>
            <Field name="feedback">
              {({ field, form }) => (
                <Textarea
                  {...field}
                  variant="filled"
                  boxShadow="sm"
                  mt={0}
                  borderRadius="sm"
                  placeholder="Feel free to say anything. We won't judge."
                  size="md"
                  isFullWidth
                />
              )}
            </Field>
            <Field name="subscribed">
              {({ field, form }) => (
                <Checkbox
                  {...field}
                  size="md"
                  mt={2}
                  textAlign="left"
                  justifySelf="left"
                  alignSelf="left"
                  colorScheme="blue"
                  defaultIsChecked
                >
                  I want to be kept up to date.
                </Checkbox>
              )}
            </Field>
            <Spacer />
            <Button
              _hover={{ bg: "blue.500", color: "white" }}
              mt={6}
              mb={6}
              variant="outline"
              width="100%"
              size="lg"
              colorScheme="blue"
              type="submit"
              isLoading={props.isSubmitting}
            >
              Send
            </Button>
          </Form>
        )}
      </Formik>
    </Flex>
  )
}

import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"
import MIPInfo from "../components/sections/infosection"
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client"
import ContactForm from "../components/cards/ContactForm"
import ContactPage from "./register"
import MIPHero from "../components/sections/hero"
import FeatureSet from "../components/sections/featureSet"
import FeatureCard from "../components/cards/FeatureCard"
import { AspectRatio, Image } from "@chakra-ui/react"
import netlifyIdentity from "netlify-identity-widget"
import pie from "../images/pie.png"
import Lottie from "react-lottie"
import lheart from "../images/lheart.json"
import ldata from "../images/ldata.json"
import lcode from "../images/lcode.json"
import ExpandableFAQ from "../pages_wip/faq"

function defaultOptions(animation) {
  const datum = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
  return datum
}

function IndexPage() {
  React.useEffect(() => {
    window.netlifyIdentity = netlifyIdentity
    netlifyIdentity.init()
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <Navigation />
      <Header />
      <FeatureSet
        isSingle
        bg="#FFFFFC"
        child1={
          <FeatureCard
            title="Global issues need solving. We want to help."
            desc="We believe that there are global issues that need to be solved, and solved now. Problems like climate change, socio-economic inequality, pollution, and global health pandemics could cost billions of lives in our near future. Our goal is to provide a platform where users can focus on these causes and contribute to them meaningfully, with conviction that their dollar has maximum impact."
          />
        }
      />
      <FeatureSet
        bg="whitesmoke"
        child1={
          <FeatureCard
            title="Your way. Your impact."
            desc="The world's most personalized donation platform. Create your own portfolio of charities. Personalize how much of every dollar goes to each charity in your portfolio. Follow the causes of the friends, family, and influencers you care about and donate to all their favorite charities in one click. There should never be friction when you want to do good."
          />
        }
        child2={
          <AspectRatio>
            <Image src={pie} w="100%" h="100%" />
          </AspectRatio>
        }
      />
      <FeatureSet
        bg="white"
        child2={
          <FeatureCard
            title="One account to save them all."
            desc="We partner with your favorite charities to provide accurate impact reports and timely news. Subscribe to nonprofits or your favorite causes to get impact reports and relevant news as soon as they drop. Easily download all your donation receipts to integrate with your favorite tax software come tax season. Automatically donate a fixed percentage of every paycheck into your personal cause."
          />
        }
        child1={
          <Lottie options={defaultOptions(lheart)} height="20%" width="50%" />
        }
      />
      <FeatureSet
        bg="whitesmoke"
        child1={
          <FeatureCard
            title="Data-driven everything."
            desc="Maximize your impact per dollar.

We make it easy to research and analyze a nonprofit before you donate. View a charity’s financials, impact reports, key stakeholders, and more in one place. Say goodbye to scams, and hello to making an actual impact."
          />
        }
        child2={
          <Lottie options={defaultOptions(ldata)} height="20%" width="80%" />
        }
      />
      <FeatureSet
        bg="#191919"
        color="white"
        child1={
          <FeatureCard
            title="Developer First."
            desc="Datasets filled with nonprofit data to explore and visualize with our GraphQL API. We're constantly working on the right abstractions in order to reduce the friction of doing good."
          />
        }
        child2={
          <Lottie options={defaultOptions(lcode)} height="20%" width="80%" />
        }
      />
      <ExpandableFAQ />
      <Footer />
    </Layout>
  )
}

export default IndexPage

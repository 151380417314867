import React from "react"
import styled, { keyframes } from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FiChevronDown } from "react-icons/fi"
import {
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Flex,
  Box,
  HStack,
  useDisclosure,
  SimpleGrid,
} from "@chakra-ui/react"

import { Container } from "../global"
import Navigation from "../../components/common/navigation/navigation"
import SignupModal from "../cards/SignupModal"

function Header() {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "green-skew" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flexer>
          <HeaderTextGroup>
            <h1>
              Create your legacy in solving the world's greatest problems.
            </h1>
            <Text color="white" py={4}>
              The first 10,000 to sign up will be invited to the open beta and
              will receive access to exclusive bonuses!
            </Text>
            <Link style={{ textDecoration: "none" }} to={"../../register"}>
              <HeaderButton>Sign Up Now</HeaderButton>
            </Link>
          </HeaderTextGroup>
          <ImageWrapper>
            <h4
              style={{
                textAlign: "center",
                color: "white",
                fontSize: 20,
              }}
            >
              Coming soon to iOS and Android devices
            </h4>
            <StyledImage fluid={data.file.childImageSharp.fluid} />
            <br />
          </ImageWrapper>
        </Flexer>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const DonateLink = styled(Link)`
  color: white;
  padding: 20;
  font-size: 20px;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
`

const gradientAnimation = keyframes`
    0%{background-position:0% 0%}
    25%{background-position:50% 50%}
    50%{background-position:100% 100%}
    75%{background-position:50% 50%}
    100%{background-position:0% 0%}
`

const HeaderWrapper = styled.header`
  background: #feac5e; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    60deg,
    #feac5e,
    #c779d0,
    #4bc0c8
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    60deg,
    #feac5e,
    #c779d0,
    #4bc0c8
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: 300% 100%;
  animation: ${gradientAnimation} 10s ease-in infinite;
  display: flex;
  min-height: 75vh;
  padding-top: 5vh;
  padding-bottom: 5vh;
  flex-direction: column;
  justify-content: center;
`
const HeaderTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  div {
    width: 100%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      margin: 0 0px;
    }
  }

  h1 {
    line-height: 1em;
    color: white;
    ${(props) => props.theme.font_size.xxxl};
    @media (max-width: ${(props) => props.theme.screen.md}) {
      ${(props) => props.theme.font_size.xxl};
    }
  }

  h2 {
    margin-bottom: 2em;
    font-size: 20px;
    font-weight: 30;
  }
`

const Flexer = styled.div`
  display: grid;
  width: 100%;
  justify-content: space-evenly;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const FormSubtitle = styled.span`
  ${(props) => props.theme.font_size.xsmall};
  color: white;
`

// const FormSubtitleLink = styled(Link)`
//   color: ${props => props.theme.color.secondary};
//   padding-bottom: 1px;
//   margin-left: 8px;
//   text-decoration: none;
//   border-bottom: 1px solid ${props => props.theme.color.secondary};
// `

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

export const HeaderButton = styled.button`
  font-weight: 600;
  font-size: 14px;
  color: black;
  letter-spacing: 2px;
  border-radius: 5px;
  height: 60px;
  display: block;
  position: relative;
  top: 0;
  text-transform: uppercase;
  border: 1px solid transparent;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 10px 40px -10px;
  cursor: pointer;
  white-space: nowrap;
  background: white;
  padding: 0px 40px;
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    transform: 35deg;
    top: -5px;
    transition: 0.2s ease-in;
    border: 1px solid white;
    text-underline-offset: 5px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-left: 0;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  padding-top: 1rem;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled(Img)`
  width: 550px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`

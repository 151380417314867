import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"

const MIPInfo = () => (
  <Section style={{ backgroundColor: "#292929" }} id="want to help out?">
    <StyledContainer>
      <SectionTitle>
        Want to help shape the future of philanthropy?
      </SectionTitle>
      <div
        style={{
          textAlign: "left",
          margin: "auto",
          paddingBottom: 0,
          color: "whitesmoke",
          fontSize: 18,
        }}
      >
        We're looking for suggestions, feedback, and insight into how giving can
        be made better. Our mission is to strive to make philanthropy as
        effortless and intuitive as possible. Send us an email at
        hi@myimmortalityproject.org and let's chat!
      </div>
      <SectionTitle>Want to do EVEN more?</SectionTitle>
      <div
        style={{
          textAlign: "left",
          margin: "auto",
          paddingBottom: 0,
          color: "whitesmoke",
          fontSize: 18,
        }}
      >
        Explore our job postings on AngelList or shoot us an email about you and
        we can create a role for you if need be!
      </div>
    </StyledContainer>
  </Section>
)

export default MIPInfo

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  display: flex;
  justify-content: flex-start;
  margin: auto;
  text-align: left;
  color: white;
  font-size: 30px;
  padding: 50px 0 20px 0px;
`

import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"
import {
  Center,
  Square,
  Circle,
  Heading,
  Divider,
  Grid,
  Text,
  Box,
  Flex,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Spacer,
  AccordionIcon,
} from "@chakra-ui/react"
import objectArr from "../markdown/faq.json"

function AccordianQuestion({ question, answer, id, quote }) {
  const highlighterColors = [
    "#30C5FF50",
    "#8cff3250",
    "#fdff3250",
    "#ff149350",
    "#A117F250",
    "#f3953950",
  ]

  return (
    <AccordionItem mb={4} is>
      <AccordionButton
        _expanded={{
          bg: highlighterColors[id % highlighterColors.length],
        }}
      >
        <Box textAlign="left" fontWeight="bold" fontSize="large">
          {question}
        </Box>
        <Spacer />
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel fontWeight="normal" textAlign="left" fontSize="medium">
        {answer}
      </AccordionPanel>
      <Divider />
    </AccordionItem>
  )
}

export function ExpandableFAQ() {
  return (
    <Box maxWidth="container.md" margin="auto" padding={6}>
      <Accordion allowToggle allowMultiple>
        {objectArr.map((element, index) => {
          return (
            <AccordianQuestion
              question={element.question}
              id={index}
              answer={element.answer}
            />
          )
        })}
      </Accordion>
    </Box>
  )
}

function HeadingBox({ heading }) {
  return (
    <Center padding={6}>
      <Heading textAlign="center">{heading}</Heading>
    </Center>
  )
}

function StillNeedHelp() {
  return (
    <Alert
      status="info"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="200px"
    >
      <AlertIcon boxSize="40px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Need more help?
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        We're always looking to improve! Contact us and we're happy to help!
      </AlertDescription>
      <a href="mailto:rohan@myimmortalityproject.com">Find us here</a>
    </Alert>
  )
}

const FAQPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <HeadingBox heading="Frequently Asked Questions" />
    <ExpandableFAQ />
    <StillNeedHelp />
  </Layout>
)

export default FAQPage

import React from "react"
import styled from 'styled-components'

import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      <SectionTitle>Donation Made Easier</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Effortlessly Donate</FeatureTitle>
          <FeatureText>
            Donate to over 10,000 US-based charities
            all vetted as legitimate 501(c)(3) organizations.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Secure & Transparent</FeatureTitle>
          <FeatureText>
            All our payments are handled 100% securely. No hidden costs.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Data Driven Insights</FeatureTitle>
          <FeatureText>
            Easily research and view insights into your favorite non-profits. Find out what percentage of every dollar donated actually goes to their cause.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Greater Impact</FeatureTitle>
          <FeatureText>
            Transaction cost is minimized, more of your dollar goes to those that need it most.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
      <br/><br/><br/><br/>
      <SectionTitle primary>Donation Made Better</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Personalized Causes</FeatureTitle>
          <FeatureText>
            Create groups of charities and assign them weight. Be the manager of your own portfolio of charities.
            Send your cause to your friends and family and follow the causes of those you care about.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Your Own Philanthropic Dashboard</FeatureTitle>
          <FeatureText>
            See how you donate and the impact of your donations. User friendly charts and graphs so you can maximize your own brand of philanthropy.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Lost Donation Receipts?</FeatureTitle>
          <FeatureText>
          Everything in one place come tax season. No more searching through email or worse.</FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Save The World</FeatureTitle>
          <FeatureText>
            Focus on the causes you care about and let us worry about the details. Follow charities and causes to recieve insight that directly shows how you've helped change the world.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.secondary}; 
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`;



const FeaturesGrid = styled.div`
  max-width: 770px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 50px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 5px;
  text-align:center;
`

const FeatureText = styled.p`
  text-align: center;
`

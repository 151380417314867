import React from "react"
import styled, { keyframes } from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FiChevronDown } from "react-icons/fi"
import {
  Center,
  Flex,
  Heading,
  Text,
  Box,
  Button,
  SimpleGrid,
  VStack,
  Checkbox,
  useMediaQuery,
} from "@chakra-ui/react"
import Lottie from "react-lottie"
import animationData from "../../images/dashperson.json"

// const B = (props) => (
//   <Text style={{ fontWeight: "bold", margin: "0px", padding: "0px" }}>
//     {props.children}
//   </Text>
// )

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

export default function MIPHero() {
  const [isLargerThan700] = useMediaQuery("(min-width: 1000px)")

  return (
    <SimpleGrid
      bg="whitesmoke"
      w="100%"
      columns={isLargerThan700 ? 2 : 1}
      spacing={10}
      flexDir="column"
      justify="flex-start"
      padding="6em 4em"
    >
      <Box textAlign="left" alignSelf="flex-start" width="100%" color="black">
        <Heading size="4xl" fontFamily="Avenir" fontWeight="bold">
          The world’s <b><Box color="#956bd5">most effective</Box></b> donation
          platform.
        </Heading>
        <Text fontSize="2xl" fontFamily="Avenir" lineHeight={8}>
          A data-driven way to give. Create <b>your</b> legacy in solving the
          world's greatest problems.
        </Text>
        <HeaderForm
          name="Sign-Up"
          method="post"
          data-netlify-honeypot="bot-field"
          data-netlify="true"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="Sign-Up" />
          <HeaderInput
            type="email"
            placeholder="Your email"
            name="email"
            id="email"
            required
          />
          <HeaderButton>Say Hello</HeaderButton>
        </HeaderForm>
      </Box>
      {isLargerThan700 && (
        <Lottie options={defaultOptions} height="100%" width="100%" />
      )}
    </SimpleGrid>
  )
}

const HeaderForm = styled.form`
  display: flex;
  margin-top: 4em;
  flex-direction: row;
  align-self: flex-start;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.primary};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

export const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: black;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 10px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: white;
  padding: 0px 40px;
  border: 1px solid black;
  outline: 0px;
  &:hover {
    background: #191919;
    border-color: white;
    color: white;
    opacity: 0.85;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
